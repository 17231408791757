import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Flex, Heading, Text, Button } from '@chakra-ui/react'
import { FaChevronRight } from 'react-icons/fa'

const OurLocationItem = (props) => (
    <Flex flexWrap="wrap-reverse">
        <Box w={{ sm: '100%', md: '50%'}} p="4" mb="4">
            <GatsbyImage 
                image={props.src}
                alt={props.alt}
            />
            <Box display={{ sm: 'block', md: 'none'}}>
            {props.listingLink ? (
                    <a href={props.listingLink}>
                    <Button variant="ghost"
                        color="pine.300"
                        textTransform="none"
                        fontSize="1.25rem"
                        pl="0"
                        _hover={{
                            backgroundColor: 'none',
                            color: 'pine.600'
                        }}>
                        View Properties <FaChevronRight />
                    </Button>
                    </a>
                ) : <Text color="pine.300"
                textTransform="none"
                fontSize="1.25rem"
                pl="0">Coming Soon!</Text> }
            </Box>
        </Box>
        <Box  w={{ sm: '100%', md: '50%'}}  px="4" py="2">
            <Heading mb="2">{props.location}</Heading>
            <Text lineHeight="2">{props.description}</Text>
            <Box display={{ sm: 'none', md: 'block'}}>
                {props.listingLink ? (
                    <a href={props.listingLink} target="_blank" rel="noopener noreferrer">
                    <Button variant="ghost"
                        color="pine.300"
                        textTransform="none"
                        fontSize="1.25rem"
                        pl="0"
                        _hover={{
                            backgroundColor: 'none',
                            color: 'pine.600'
                        }}>
                        View Properties <FaChevronRight />
                    </Button>
                    </a>
                ) : <Text color="pine.300"
                textTransform="none"
                fontSize="1.25rem"
                pl="0">Coming Soon!</Text> }
            
            </Box>
        </Box>
    </Flex>
)


export default OurLocationItem