import * as React from "react"
import { graphql } from 'gatsby'
import { Container, Box, Center, Heading, Flex, Link } from '@chakra-ui/react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Seo from "../components/seo"
import OurLocationItem from "../components/OurLocations/OurLocationItem"


const OurLocations = ({ data }) => {

  const santaRosa = getImage(data.SantaRosaImg)
  const victor = getImage(data.VictorImg)
  const kanab = getImage(data.KanabImg)
  const twinFalls = getImage(data.TwinFallsImg)

  const PropertyLink = (props) => (
    <Link
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
      variant="ghost"
        color="pine.300"
        fontWeight="700"
        textTransform="none"
        fontSize="1.25rem"
        display="block"
        _hover={{
            backgroundColor: 'none',
            color: 'pine.600',
            textDecoration: 'none'
        }}>
        {props.location}
    </Link>
  )

  return (
  <>
    <Seo title="Book New West Properties on booking.com" />
    <Container my={{sm: '8rem', md: '12rem' }} maxW="container.xl">
    <Box w={{ sm: '100%', md: '60%'}} textAlign="center" mx="auto" mb="8">
        <Heading as="h1" px="8" color="pine.300" textTransform="uppercase" fontSize="3rem" lineHeight="1" textAlign="center" >
          Book now on Booking.com
        </Heading>
      </Box>
      <Container maxW="container.md">
        <Flex flexWrap="wrap-reverse">
            <Box w={{ sm: '100%', md: '50%'}} p="4" mb="4">
                <GatsbyImage 
                    image={santaRosa}
                    alt="Santa Rosa property"
                />
            </Box>
            <Box  w={{ sm: '100%', md: '50%'}}  px="4" py="2">
                <Heading mb="2">Santa Rosa, CA</Heading>
                <Box>
                  <PropertyLink link="https://www.booking.com/hotel/us/historic-santa-rosa-cottage-santa-rosa.html" location="Historic Santa Rosa Cottage" />
                  <PropertyLink link="https://www.booking.com/hotel/us/downtown-urban-flat-close-to-smart-station.html" location="Downtown Urban Flat" />
                  <PropertyLink link="https://www.booking.com/hotel/us/retro-bungalow-santa-rosa1.html" location="Downtown Retro Bungalow" />
                  <PropertyLink link="https://www.booking.com/hotel/us/cannery-loft-railroad-square.html" location="Cannery Loft at Railroad Square" />
                  <PropertyLink link="https://www.booking.com/hotel/us/historic-house-two-units-in-one-large-home.html" location="Historic House" />
                
                </Box>
            </Box>
        </Flex>

        <Flex flexWrap="wrap-reverse">
            <Box w={{ sm: '100%', md: '50%'}} p="4" mb="4">
                <GatsbyImage 
                    image={victor}
                    alt="Victor property"
                />
            </Box>
            <Box  w={{ sm: '100%', md: '50%'}}  px="4" py="2">
                <Heading mb="2">Victor, ID</Heading>
                <Box>
                  <PropertyLink link="https://www.booking.com/hotel/us/bear-lair-at-teton-valley-idaho.html" location="Bear Lair" />
                  <PropertyLink link="https://www.booking.com/hotel/us/moose-willow-at-teton-valley-idaho.html" location="Moose Willow" />
                </Box>
            </Box>
        </Flex>

        <Flex flexWrap="wrap-reverse">
            <Box w={{ sm: '100%', md: '50%'}} p="4" mb="4">
                <GatsbyImage 
                    image={kanab}
                    alt="Kanab property"
                />
            </Box>
            <Box  w={{ sm: '100%', md: '50%'}}  px="4" py="2">
                <Heading mb="2">Kanab, UT</Heading>
                <Box>
                  <PropertyLink link="https://www.booking.com/hotel/us/hollywood-hangout-new-west-properties.html" location="Hollywood Hangout" />
                  <PropertyLink link="https://www.booking.com/hotel/us/red-canyon-bunkhouse-at-kanab-new-west-properties.html" location="Red Canyon Bunkhouse" />
                  <PropertyLink link="https://www.booking.com/hotel/us/searchers-hideaway-at-kabab-new-west-properties.html" location="Searchers Hideaway" />
                  <PropertyLink link="https://www.booking.com/hotel/us/3x3-sanctuary-at-kanab-new-west-properties.html" location="3x3 Sanctuary" />
                </Box>
            </Box>
        </Flex>

        <Flex flexWrap="wrap-reverse">
            <Box w={{ sm: '100%', md: '50%'}} p="4" mb="4">
                <GatsbyImage 
                    image={twinFalls}
                    alt="Kanab property"
                />
            </Box>
            <Box  w={{ sm: '100%', md: '50%'}}  px="4" py="2">
                <Heading mb="2">Twin Falls, ID</Heading>
                <Box>
                  <PropertyLink link="https://www.booking.com/hotel/us/travelers-hideout-1.html" location="Traveler's Hideout #1" />
                </Box>
            </Box>
        </Flex>

        </Container>
      </Container>
  </>
  )
}

export const query = graphql`
  query {
    SantaRosaImg: file(relativePath: {eq: "santa-rosa.png"}) {
      childImageSharp {
        gatsbyImageData(
          quality: 8
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 1
        )
      }
    }
    VictorImg: file(relativePath: {eq: "victor.png"}) {
      childImageSharp {
        gatsbyImageData(
          quality: 8
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 1
        )
      }
    }
    KanabImg: file(relativePath: {eq: "kanab.png"}) {
      childImageSharp {
        gatsbyImageData(
          quality: 8
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 1
        )
      }
    }
    TwinFallsImg: file(relativePath: {eq: "twin-falls.png"}) {
      childImageSharp {
        gatsbyImageData(
          quality: 8
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 1
        )
      }
    }
  }
`

export default OurLocations
